import "./admin-panel-orders.css";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import useGetOrders from "../components/hooks/useGetOrders";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import socket from "../../helpers/socket";

const AdminPanelOrders = () => {
  const theme = useTheme();
  const initialOrders = useGetOrders();

  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  //console.log(orders)
  const isScreenSmall = useMediaQuery("(max-width: 1400px)");
  const [selected, setSelected] = useState("");
  const [sortOrder, setSortOrder] = useState(null);

  useEffect(() => {
    if (initialOrders && Array.isArray(initialOrders)) {
      setOrders(initialOrders);
    }
  }, [initialOrders]);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleOrderClick = (orderId) => {
    navigate(`/admin/admin-panel/order-details/${orderId}`);
  };


  useEffect(() => {
    socket.on("orderUpdate", (updatedOrder) => {
      setOrders((prevOrders) => {
        const existingOrderIndex = prevOrders.findIndex(
          (order) => order._id === updatedOrder._id
        );
  
        if (existingOrderIndex >= 0) {
          const updatedOrders = [...prevOrders];
          updatedOrders[existingOrderIndex] = updatedOrder;
          return updatedOrders;
        } else {
          return [...prevOrders, updatedOrder];
        }
      });
    });
  
    return () => {
      socket.off("orderUpdate");
    };
  }, [setOrders]);

  const handleSortByDate = () => {
    if (sortOrder === null) {
      setSortOrder("asc");
    } else if (sortOrder === "asc") {
      setSortOrder("desc");
    } else {
      setSortOrder(null);
    }
  };

  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("/");
    const [hour, minute, second] = timePart.split(":");
    return new Date(year, month - 1, day, hour, minute, second);
  };

  const formatDate = (timeCreated) => {
    if (!timeCreated) return "";

    try {
      const [datePart] = timeCreated.split(", ");
      const [day, month, year] = datePart.split("/");

      const parsedDate = new Date(year, month - 1, day);
      return parsedDate.toLocaleDateString("en-GB");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const sortedOrders = Array.isArray(orders)
    ? orders
        .filter((order) => {
          if (selected === "onWait") {
            return !order.shipped;
          } else if (selected === "sent") {
            return order.shipped;
          }
          return true;
        })
        .sort((a, b) => {
          if (sortOrder === "asc") {
            return parseDate(a.timeCreated) - parseDate(b.timeCreated); 
          } else if (sortOrder === "desc") {
            return parseDate(b.timeCreated) - parseDate(a.timeCreated); 
          } else {
            return 0;
          }
        })
    : [];

  if (!Array.isArray(orders)) return null;
  if (!orders || orders === null) return null;

  return (
    <Box sx={{ margin: "0 1vw", minHeight: "100vh" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "0.5fr 1fr 2fr 1.2fr 1.2fr 1fr 0.8fr 0.8fr",
          gap: 2,
          borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
          fontSize: isScreenSmall ? "0.8em" : "0.95em",
          textAlign: "left",
          height: "64px",
          alignItems: "center",
        }}
      >
        <Typography variant="body1"></Typography>
        <Typography variant="body1">NAME</Typography>
        <Typography variant="body1">EMAIL</Typography>
        <Typography variant="body1">PRODUCTS</Typography>
        <Typography variant="body1">PRICE</Typography>
        <Typography
          component="div"
          variant="body1"
          onClick={handleSortByDate}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            minWidth: "100px",
          }}
        >
          DATE
          <Box sx={{ marginLeft: "5px", minWidth: "10px" }}>
            {sortOrder === "asc" ? "▲" : sortOrder === "desc" ? "▼" : ""}
          </Box>
        </Typography>
        <Typography variant="body1">ID</Typography>
        <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
          <Select
            value={selected}
            onChange={handleChange}
            displayEmpty
            sx={{
              minWidth: 80,
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="onWait">On hold</MenuItem>
            <MenuItem value="sent">Sent</MenuItem>
          </Select>
        </Box>
      </Box>

      {sortedOrders.map((order, index) => (
        <Box
          key={order._id}
          sx={{
            display: "grid",
            gridTemplateColumns: "0.5fr 1fr 2fr 1.2fr 1.2fr 1fr 0.8fr 0.8fr",
            gap: 2,
            alignItems: "center",
            fontSize: isScreenSmall ? "0.8em" : "0.95em",
            textAlign: "left",
            cursor: "pointer",
            height: "64px",
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          onClick={() => handleOrderClick(order._id)}
        >
          <Typography sx={{ ml: 1 }}>{index + 1}</Typography>
          <Typography sx={{ overflow: "hidden" }}>{order.name}</Typography>
          <Typography sx={{ overflow: "hidden" }}>{order.email}</Typography>
          <Typography sx={{ overflow: "hidden" }}>
            Products: {order.products.length}
          </Typography>
          <Typography sx={{ overflow: "hidden" }}>
            {order.totalPrice.toLocaleString("de-DE")},00
          </Typography>
          <Typography sx={{ overflow: "hidden" }}>
            {formatDate(order.timeCreated)}
          </Typography>
          <Typography sx={{ overflow: "hidden" }}>{order.frontendOrderID}</Typography>
          <Typography
            sx={{
              color: order.shipped ? "green" : "red",
              textAlign: "right",
              mr: 1,
            }}
          >
            {order.shipped ? "Sent" : "On hold"}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default AdminPanelOrders;
