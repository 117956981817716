import { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL, NOTIFICATION_TYPES } from "../app.constants";
import { displayNotification } from "../../store/reducers/notificationSlice";
import { useDispatch } from "react-redux";
import { applyDiscountCode } from "../../store/reducers/cartSlice";

const useValidateDiscountCode = (discountCode) => {
  const dispatch = useDispatch();
  const [discountData, setDiscountData] = useState({ isValid: false, discountAmount: 0 });

  useEffect(() => {
    if (!discountCode) {
      setDiscountData({ isValid: false, discountAmount: 0 });
      // Reset Redux discount state
      dispatch(applyDiscountCode({ code: null, amount: 0 }));
      return;
    }

    const validateCode = async () => {
      try {
        const response = await axios.post(`${SERVER_URL}admin/validatediscountcode`, { code: discountCode });

        if (response.data.success) {
          const { discountAmount } = response.data;

          // Update local state
          setDiscountData({ isValid: true, discountAmount });

          // Update Redux state
          dispatch(applyDiscountCode({ code: discountCode, amount: discountAmount }));

          // Display success notification
          dispatch(
            displayNotification({
              text: "Discount code applied successfully!",
              type: NOTIFICATION_TYPES.SUCCESS,
            })
          );
        } else {
          resetDiscount();
        }
      } catch (error) {
        console.error("Error validating discount code:", error.response?.data || error.message);
        resetDiscount();

        // Display error notification
        dispatch(
          displayNotification({
            text: "Failed to validate discount code.",
            type: NOTIFICATION_TYPES.ERROR,
          })
        );
      }
    };

    const resetDiscount = () => {
      setDiscountData({ isValid: false, discountAmount: 0 });
      dispatch(applyDiscountCode({ code: null, amount: 0 }));
    };

    validateCode();
  }, [discountCode, dispatch]);

  return discountData; // Return validation status and discount amount
};

export default useValidateDiscountCode;


