import "./admin-panel-order-details.css";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import {
  deleteOrder,
  dispatchFetchOrder,
  markOrderAsShipped,
  fetchReceiptNumber,
  fetchDiscountCode,
} from "../../adminPanel.actions";
import { DATA_STATE, NOTIFICATION_TYPES } from "../../../helpers/app.constants";
import {
  displayNotification,
  setDataState,
} from "../../../store/reducers/notificationSlice";
import { getProductsByIds } from "../hooks/getProductsByIds";
import CustomerDetails from "./CustomerDetails";
import ProductList from "./ProductList";
import OrderStatus from "./OrderStatus";
import { parseDate } from "./utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectScreenSize } from "../../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";

const AdminPanelOrderDetails = ({ setActiveTab, ordersTab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  const [order, setOrder] = useState(null);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [codeDiscountAmount, setCodeDiscountAmount] = useState(null);

  const [expandedSections, setExpandedSections] = useState({
    customerInfo: true,
    orderInfo: false,
    status: false,
  });

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSectionToggle = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    if (id) {
      dispatch(dispatchFetchOrder(id))
        .then((data) => {
          setOrder(data);
          setReceiptNumber(data.receiptNumber || "");

          if (!data.receiptNumber) {
            return dispatch(fetchReceiptNumber());
          }
          return Promise.resolve();
        })
        .then((receipt) => {
          if (receipt) {
            setReceiptNumber(receipt);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch order or receipt number", error);
        });
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (order) {
      const fetchProducts = async () => {
        try {
          const products = await getProductsByIds(order, dispatch);
          setFetchedProducts(products);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
      fetchProducts();
    }
  }, [order, dispatch]);

  useEffect(() => {
    if (order?.discountCode) {
      dispatch(fetchDiscountCode())
        .then(({ discountAmount }) => {
          setCodeDiscountAmount(discountAmount); // This sets the state
        })
        .catch((error) => {
          console.error("Failed to fetch discount code amount:", error);
        });
    }
  }, [order?.discountCode, dispatch]);

  const handleMarkOrderAsShipped = () => {
    if (!receiptNumber) {
      const errorPayload = {
        text: "No receipt number available!",
        type: NOTIFICATION_TYPES.ERROR,
      };
      dispatch(displayNotification(errorPayload));
      return;
    }

    dispatch(markOrderAsShipped(id, receiptNumber)).then(() => {
      const notificationPayload = {
        text: "Order marked as shipped!",
        type: NOTIFICATION_TYPES.SUCCESS,
      };
      dispatch(displayNotification(notificationPayload));
      dispatch(dispatchFetchOrder(id)).then((data) => setOrder(data));
    });
  };

  const handleDeleteOrder = () => {
    dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
    dispatch(deleteOrder(id)).then(() => {
      const notificationPayload = {
        text: "Order deleted!",
        type: NOTIFICATION_TYPES.SUCCESS,
      };
      dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
      dispatch(displayNotification(notificationPayload));
      setActiveTab(ordersTab);
      navigate("/admin/admin-panel");
    });
  };

  // Check if discount code is applied
  const discountCodeApplied = order?.discountCode === true;

  // Calculate the base total price (product prices without discounts)
  const baseTotalPrice =
    order && fetchedProducts.length > 0
      ? order.products.reduce((acc, product) => {
          const fullProduct = fetchedProducts.find(
            (p) => p._id === product.productID
          );
          return fullProduct ? acc + fullProduct.price * product.count : acc;
        }, 0)
      : 0;

  // Calculate total price including product-level discounts (only if discount code is not applied)
  const totalPrice = discountCodeApplied
    ? baseTotalPrice // Use base price without product discounts when a discount code is applied
    : fetchedProducts.reduce((acc, product) => {
        const orderProduct = order.products.find(
          (p) => p.productID === product._id
        );
        if (orderProduct) {
          const productDiscount = product.discountAmount || 0;
          const discountedPrice =
            product.price - (product.price * productDiscount) / 100;
          return acc + discountedPrice * orderProduct.count; // Use discounted price for each product
        }
        return acc;
      }, 0);

  // Calculate product-level discount amount
  const productLevelDiscountAmount = discountCodeApplied
    ? 0 // Skip product-level discounts when discount code is applied
    : baseTotalPrice - totalPrice; // Difference between base and discounted prices

  // Calculate code-level discount amount
  const codeDiscountAmountValue = discountCodeApplied
    ? (codeDiscountAmount / 100) * baseTotalPrice // Apply code-based discount on the base total price
    : 0;

  // Choose the appropriate discount amount
  const finalDiscountAmount = discountCodeApplied
    ? codeDiscountAmountValue // Use code-based discount
    : productLevelDiscountAmount; // Use product-level discount

  // Calculate the final price
  const finalPrice = Math.max(baseTotalPrice - finalDiscountAmount, 0); // Avoid negative prices

  // Debugging Logs
  // console.log("Base Total Price:", baseTotalPrice);
  // console.log("Total Product Price:", totalPrice);
  // console.log("Product Level Discount Amount:", productLevelDiscountAmount);
  // console.log("Code Discount Amount:", codeDiscountAmountValue);
  // console.log("Final Discount Amount:", finalDiscountAmount);
  // console.log("Final Price:", finalPrice);

  const customerData = order
    ? [
        { label: "Name", value: order.name },
        { label: "Email", value: order.email },
        { label: "Address", value: order.address },
        { label: "City/ZipCode", value: `${order.city}/${order.zipCode}` },
        { label: "Phone number", value: order.phoneNumber },
        {
          label: "Payment",
          value:
            order.paymentMethod === "cashOnDelivery"
              ? "Cash on Delivery"
              : order.paymentMethod === "bankAccount"
              ? "Pay to Bank Account"
              : order.paymentMethod,
        },
        {
          label: "Date of order",
          value: parseDate(order.timeCreated).toLocaleString("en-GB"),
        },
      ]
    : [];

  if (!order) {
    return <div></div>;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "0 1vw",
        minWidth: 1000,
        width: "96%",
        minHeight: "100vh",
      }}
    >
      <Accordion
        expanded={expandedSections.customerInfo}
        onChange={() => handleSectionToggle("customerInfo")}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            sx={{
              color: expandedSections.customerInfo
                ? theme.palette.blue.main
                : "inherit",
            }}
            variant="h6"
          >
            Customer Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomerDetails customerData={customerData} />
        </AccordionDetails>
      </Accordion>
      <Divider />

      <Accordion
        expanded={expandedSections.orderInfo}
        onChange={() => handleSectionToggle("orderInfo")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            sx={{
              color: expandedSections.orderInfo
                ? theme.palette.blue.main
                : "inherit",
            }}
            variant="h6"
          >
            Order Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            ORDER ID: <strong>{order?.frontendOrderID || "/"}</strong>
          </Typography>
          <Typography>
            DISCOUNT CODE: <strong>{discountCodeApplied ? "Yes" : "No"}</strong>
          </Typography>
          <ProductList
            products={order.products}
            fetchedProducts={fetchedProducts}
            discountCode={discountCodeApplied}
            isSmallScreen={isSmallScreen}
          />
          <Typography
            variant="h6"
            sx={{ mt: 2, color: "green", textAlign: "center" }}
          >
            Total Order Price: {Number(baseTotalPrice).toLocaleString("de-DE")}
            ,00 RSD
          </Typography>

          {finalDiscountAmount > 0 && (
            <Typography
              variant="h6"
              sx={{ color: "red", textAlign: "center", mt: 1 }}
            >
              {discountCodeApplied
                ? `Discount Amount (Code - ${codeDiscountAmount}%): -${Number(
                    finalDiscountAmount
                  ).toLocaleString("de-DE")},00 RSD`
                : `Total Discount Amount: -${Number(
                    finalDiscountAmount
                  ).toLocaleString("de-DE")},00 RSD`}
            </Typography>
          )}

          <Typography
            variant="h6"
            sx={{ mt: 2, color: "blue", textAlign: "center" }}
          >
            Final Total Price: {Number(finalPrice).toLocaleString("de-DE")},00
            RSD
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Divider />

      <Accordion
        expanded={expandedSections.status}
        onChange={() => handleSectionToggle("status")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            sx={{
              color: expandedSections.status
                ? theme.palette.blue.main
                : "inherit",
            }}
            variant="h6"
          >
            Status
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrderStatus
            order={order}
            receiptNumber={receiptNumber}
            onReceiptNumberChange={(e) => setReceiptNumber(e.target.value)}
            onMarkAsShipped={handleMarkOrderAsShipped}
            onDeleteOrder={handleOpenDeleteModal}
          />
        </AccordionDetails>
      </Accordion>

      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Delete Order</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the order?</Typography>
          <Typography>
            Order ID: <strong>{id}</strong>
          </Typography>
          <Typography>
            Customer: <strong>{order.name}</strong>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="customOpposite">
            Cancel
          </Button>
          <Button onClick={handleDeleteOrder} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default AdminPanelOrderDetails;
