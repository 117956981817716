import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import {
  saveDiscountCode,
  fetchDiscountCode,
} from "../adminPanel.actions";
import { displayNotification } from "../../store/reducers/notificationSlice";
import CustomField from "../../components/other/CustomField";

const AdminDiscountCode = () => {
  const dispatch = useDispatch();
  const [discountCode, setDiscountCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [error, setError] = useState("");
  const codeLength = 10; // Specific number of characters for the discount code

  useEffect(() => {
    const fetchExistingCode = async () => {
      try {
        const response = await dispatch(fetchDiscountCode());
        setDiscountCode(response.code || "");
        setDiscountAmount(response.discountAmount || "");
      } catch (error) {
        dispatch(
          displayNotification({
            text: "Failed to fetch discount code.",
            type: "error",
          })
        );
      }
    };
    fetchExistingCode();
  }, [dispatch]);

  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    for (let i = 0; i < codeLength; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setDiscountCode(code);
    setError("");
  };

  const handleSaveCode = async () => {
    if (discountCode.length !== codeLength) {
      setError(`Discount code must be exactly ${codeLength} characters long.`);
      return;
    }

    if (!discountAmount || isNaN(discountAmount) || discountAmount <= 0) {
      setError("Please enter a valid discount amount.");
      return;
    }

    try {
      await dispatch(saveDiscountCode(discountCode, parseFloat(discountAmount)));
      setError("");
      dispatch(
        displayNotification({
          text: "Discount code saved successfully!",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        displayNotification({
          text: "Failed to save discount code. Please try again.",
          type: "error",
        })
      );
      setError("Failed to save discount code. Please try again.");
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Manage Discount Code</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <CustomField
              label="Discount Code"
              minWidth="450px"
              size="small"
              margin="10px 0"
              value={discountCode}
              onChange={(e) => {
                setDiscountCode(e.target.value);
                if (e.target.value.length === codeLength) {
                  setError("");
                }
              }}
              disabled={false}
              regular={true}
            />
            <CustomField
              label="Discount Amount"
              minWidth="450px"
              size="small"
              margin="10px 0"
              value={discountAmount}
              onChange={(e) => setDiscountAmount(e.target.value)}
              disabled={false}
              regular={true}
            />
            {error && (
              <Typography
                sx={{ color: "red", fontSize: "0.875rem", mt: 1 }}
              >
                {error}
              </Typography>
            )}
            <Box sx={{ display: "flex", justifyContent:"right", gap: 2 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#007bff",
                  color: "white",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                }}
                onClick={generateRandomCode}
              >
                Generate Code
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#28a745",
                  color: "white",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: "#218838",
                  },
                }}
                onClick={handleSaveCode}
              >
                Save Code
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdminDiscountCode;
