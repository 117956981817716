import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";

const ProductList = ({
  products,
  fetchedProducts,
  discountCode,
  isSmallScreen,
}) => {
  // Map products to include prices and discount amounts
  const preparedProducts = products
    .map((product) => {
      const fullProduct = fetchedProducts.find(
        (p) => p._id === product.productID
      );
      return fullProduct
        ? {
            ...product,
            name: fullProduct.name,
            price: fullProduct.price,
            image: fullProduct.image,
            discountAmount: discountCode
              ? "/"
              : fullProduct.discountAmount || 0, // Show '/' when discount code is applied
          }
        : null;
    })
    .filter(Boolean); // Filter out any null values

  return (
    <Box sx={{ padding: 2 }}>
      {preparedProducts.map((product, index) => (
        <Paper elevation={3} key={index} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="h6" gutterBottom>
            Product {index + 1}
          </Typography>
          <Grid container spacing={2}>
            {/* Product Image */}
            <Grid item xs={12} sm={3}>
              <img
                src={product.image || "/placeholder-image.jpg"}
                alt={product.name}
                style={{
                  width: isSmallScreen ? "50%" : "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            </Grid>

            {/* Product Details */}
            <Grid
              item
              xs={12}
              sm={9}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Typography variant="body1">
                <span>Name: </span>
                <strong>{product.name}</strong>
              </Typography>
              <Typography variant="body2" sx={{ display: "flex" }}>
                <span style={{ width: "50px" }}>Size:</span>
                <strong>{product.size}</strong>
              </Typography>
              <Typography variant="body2" sx={{ display: "flex" }}>
                <span style={{ width: "50px" }}>Count:</span>
                <strong>x {product.count}</strong>
              </Typography>
              <Typography variant="body2">
                <span>Original Price:</span>{" "}
                {product.price.toLocaleString("de-DE")},00 RSD
              </Typography>

              {product.discountAmount !== "/" ? (
                <>
                  <Typography
                    variant="body2"
                    sx={{ color: "red", marginTop: "8px" }}
                  >
                    <span>Discount Amount:</span> -{" "}
                    {(
                      (product.price * product.discountAmount) /
                      100
                    ).toLocaleString("de-DE")}{" "}
                    RSD
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      marginTop: "4px",
                      color: "green",
                    }}
                  >
                    <span>Final Price After Discount:</span>{" "}
                    {(
                      product.price -
                      (product.price * product.discountAmount) / 100
                    ).toLocaleString("de-DE")}
                    ,00 RSD
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="body1"
                  sx={{ color: "blue", marginTop: "8px" }}
                >
                  Discount Code Applied - Price Adjusted
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default ProductList;
