import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearCart } from "../../store/reducers/cartSlice";

const TIMEOUT_DURATION = 1800000; // 30 minutes

const useSessionTimeout = () => {
  const dispatch = useDispatch();
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(clearCart());
      setIsSessionExpired(true); // Indicate session expiration
    }, TIMEOUT_DURATION);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [dispatch]);

  const resetSession = (onResetCallback) => {
    setIsSessionExpired(false);
    if (onResetCallback) onResetCallback(); // Optional callback for redirection or other actions
  };

  return { isSessionExpired, resetSession };
};

export default useSessionTimeout;
