import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  setDataState,
} from "../../store/reducers/notificationSlice";
import { DATA_STATE, SERVER_URL } from "../app.constants";

const useApplyDiscount = (products) => {
  const dispatch = useDispatch();
  const [discountedProducts, setDiscountedProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const applyDiscount = async () => {
      if (!products || products.length === 0) {
		console.warn("No products to apply discount for.");
		return;
	  }
	  //console.log("Payload for discount:", products);
      //dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

      try {
        const response = await axios.post(`${SERVER_URL}admin/applydiscount`, {
          products,
        });

        if (response.data.success) {
          // Map the discounted products with new prices
          const updatedProducts = products.map((product) => {
            const discountedProduct = response.data.products.find(
              (p) => p.productId === product.productId
            );

            return {
              ...product,
              discountedPrice: discountedProduct?.discountedPrice || product.price,
            };
          });

          setDiscountedProducts(updatedProducts);

          // Calculate the new total price
          const newTotalPrice = updatedProducts.reduce(
            (acc, product) => acc + product.discountedPrice * product.count,
            0
          );
          setTotalPrice(newTotalPrice);
        }
      } catch (error) {
        console.error("Error applying discount:", error);
      } finally {
        dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
      }
    };

    applyDiscount();
  }, [products, dispatch]);

  return { discountedProducts, totalPrice };
};

export default useApplyDiscount;
