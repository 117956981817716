import "./products-detailed-page.css";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../store/reducers/cartSlice";
import { displayNotification } from "../../store/reducers/notificationSlice";
import { CONTACT_INFO, NOTIFICATION_TYPES } from "../../helpers/app.constants";
import useGetProductById from "../../helpers/hooks/useGetProductById";
import { useSelector } from "react-redux";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../store/reducers/userSlice";
import { translations } from "../../helpers/translations.js";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { isMobile } from "react-device-detect";
import Bredcrumbs from "../other/Bredcrumbs.js";
import { Button, Divider, Tooltip, useMediaQuery } from "@mui/material";
import useApplyDiscount from "../../helpers/hooks/useApplyDiscount.js";
import { useTheme } from "@emotion/react";
import SectionComponent from "./mainpage/SectionComponent.js";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../other/CustomButton.js";
import { selectAllProducts } from "../../store/reducers/productSlice.js";
import useGetColors from "../../helpers/hooks/useGetColors.js";

function ProductsDetailedPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { product, error } = useGetProductById(id);
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const screenSize = useSelector(selectScreenSize);
  const isMedium = useMediaQuery("(max-width: 1500px)");
  const isMediumImage = useMediaQuery("(max-width: 1000px)");
  const isSmallScreen = screenSize === "small" || isMobile;
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [sizeError, setSizeError] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const allProducts = useSelector(selectAllProducts);
  const outOfStockTimeframe = CONTACT_INFO.OUT_OF_STOCK_TIMEFRAME;
  const phone = CONTACT_INFO.SUPPORT_PHONE;
  const colors = useGetColors();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorMessage(true);
    }, 2000); // 2

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (product) {
      setSelectedImage(product.image);
    }
  }, [product]);

  const productDetails = useMemo(() => {
    return product?._id && product?.price
      ? [
          {
            productID: product._id,
            price: product.price,
            discountAmount: product.discountAmount,
          },
        ]
      : [];
  }, [product]);

  const { discountedProducts } = useApplyDiscount(productDetails, null);

  const discountedPrice =
    discountedProducts?.[0]?.discountedPrice || product?.price || 0;

  const handleAddProductClick = () => {
    if (!selectedSize) {
      setSizeError(
        translatedLanguages.selectSizeError || "Please select a size."
      );
      return;
    }
    if (product.outOfStock) {
      setShowConfirmationModal(true);
    } else if (product.outOfStockSizes?.includes(selectedSize)) {
      setShowConfirmationModal(true);
    } else {
      dispatch(addItemToCart({ product, size: selectedSize }));
      dispatch(
        displayNotification({
          text: `${product.name} ${translatedLanguages.addedToCart}!`,
          type: NOTIFICATION_TYPES.SUCCESS,
        })
      );
      setSizeError("");
    }
  };

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  if (!product) {
    return (
      <div
        className="product-detailed-page-container"
        style={{
          minHeight: "100vh",
          width: "100vw",
        }}
      ></div>
    );
  }

  if (error && showErrorMessage) {
    return (
      <div
        className="error-message"
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {translatedLanguages.errorLoadingProduct || "Error loading product."}
      </div>
    );
  }

  const getTagMatchesCount = (productTags, otherProductTags) =>
    productTags.reduce(
      (count, tag) => (otherProductTags.includes(tag) ? count + 1 : count),
      0
    );

  const getNameSimilarity = (productName, otherProductName) => {
    const productNameLower = productName.toLowerCase();
    const otherProductNameLower = otherProductName.toLowerCase();

    if (productNameLower === otherProductNameLower) {
      return 2;
    }

    const productFirstWord = productNameLower.split(" ")[0];
    const otherFirstWord = otherProductNameLower.split(" ")[0];

    if (productFirstWord === otherFirstWord) {
      return 1.5;
    }

    const productNameTokens = productNameLower.split(/\s+/);
    const otherProductTokens = otherProductNameLower.split(/\s+/);

    const hasPartialMatch =
      productNameTokens.some((token) =>
        otherProductNameLower.includes(token)
      ) || otherProductTokens.some((token) => productNameLower.includes(token));

    return hasPartialMatch ? 1 : 0;
  };

  const similarProducts = allProducts
    ?.filter((p) => p._id !== product._id)
    .map((p) => {
      const tagMatchesCount = getTagMatchesCount(product.tags, p.tags);
      const nameSimilarity = getNameSimilarity(product.name, p.name);

      return { ...p, tagMatchesCount, nameSimilarity };
    })
    .filter((p) => p.tagMatchesCount > 0 || p.nameSimilarity > 0)
    .sort((a, b) => {
      if (b.nameSimilarity !== a.nameSimilarity) {
        return b.nameSimilarity - a.nameSimilarity;
      }
      return b.tagMatchesCount - a.tagMatchesCount;
    })
    .slice(0, 4);

  similarProducts.forEach((p) => {
    //console.log(`Product: ${p.name}, Tag Matches: ${p.tagMatchesCount}, Name Similarity: ${p.nameSimilarity}`);
  });

  const colorMap = colors.reduce((acc, colorObj) => {
    acc[colorObj.name.toLowerCase()] = colorObj.hex;
    return acc;
  }, {});

  return (
    <div
      className="product-detailed-page-container"
      style={{
        minHeight: isSmallScreen ? "100vh" : "100vh",
        height: "fit-content",
        width: isSmallScreen ? "100%" : "calc(100% - 60px)",
        marginLeft: isSmallScreen ? "" : "50px",
        marginBottom: isSmallScreen ? "2vh" : isMedium ? "6vh" : "10vh",
      }}
    >
      <Bredcrumbs
        pageName={translatedLanguages.shop}
        pageLink="/shop/"
        pageName2={product.name}
      />

      <div
        className="product-detailed-product-container"
        style={{
          flexDirection: isSmallScreen ? "column" : "row",
          marginTop: isSmallScreen ? "5vh" : isMedium ? "6vh" : "5vh",
          marginBottom: isSmallScreen ? "2vh" : isMedium ? "6vh" : "10vh",
          height: isSmallScreen ? "fit-content" : "60vh",
          alignItems: isSmallScreen ? "" : "center",
        }}
      >
        <div
          className="image-gallery-container"
          style={{
            width: isSmallScreen ? "100%" : isMedium ? "35%" : "27%",
            minWidth: isSmallScreen ? "" : "275px",
          }}
        >
          <div
            className="main-image-container"
            style={{
              height: isSmallScreen
                ? "40vh"
                : isMedium
                ? "42.5vh"
                : isMediumImage
                ? "40vh"
                : "45vh",
            }}
          >
            <img
              src={selectedImage}
              alt={product.name}
              className="main-image"
            />
          </div>

          <div className="image-gallery">
            <img
              className={`thumbnail-image ${
                selectedImage === product.image ? "selected" : ""
              }`}
              src={product.image}
              alt={product.name}
              onClick={() => setSelectedImage(product.image)}
            />
            {product.image2 && (
              <img
                className={`thumbnail-image ${
                  selectedImage === product.image2 ? "selected" : ""
                }`}
                src={product.image2}
                alt={product.name}
                onClick={() => setSelectedImage(product.image2)}
              />
            )}
            {product.image3 && (
              <img
                className={`thumbnail-image ${
                  selectedImage === product.image3 ? "selected" : ""
                }`}
                src={product.image3}
                alt={product.name}
                onClick={() => setSelectedImage(product.image3)}
              />
            )}
          </div>
        </div>
        <div
          className="product-detailed-info"
          style={{
            minWidth: !isSmallScreen && "340px",
            width: isSmallScreen ? "100%" : "55%",
            marginLeft: isSmallScreen ? "" : isMedium ? "7.5vh" : "6vh",
            marginTop: isSmallScreen ? "5vh" : isMedium ? "" : "",
            marginBottom: isSmallScreen ? "5vh" : isMedium ? "" : "",
          }}
        >
          <h1>{product.name}</h1>
          {product.tags.includes("sale") ? (
            <div
              className="product-detailed-section"
              style={{ display: "flex", fontSize: "1.25em" }}
            >
              <h3 className="strikethrough">
                {product.price ? product.price.toLocaleString("de-DE") : "0"}
              </h3>
              <h3>
                {discountedPrice
                  ? discountedPrice.toLocaleString("de-DE")
                  : "0"}
                ,00 RSD
              </h3>
            </div>
          ) : (
            <h3
              style={{ fontWeight: "bold" }}
              className="product-detailed-section"
            >
              {product.price.toLocaleString("de-DE")},00 RSD
            </h3>
          )}
          <Divider
            sx={{
              width: "100%",
              backgroundColor: theme.palette.customOppositeLighter.main,
            }}
            orientation="horizontal"
            variant="left"
            flexItem
          />
          <div
            className="product-detailed-section"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <div
              className="product-detailed-options "
              style={{ marginLeft: "0" }}
            >
              <span>{translatedLanguages.filters.tags}:</span>
              {product.tags.map((tags, index) => (
                <span key={index}>#{tags}</span>
              ))}
            </div>

            <div className="product-detailed-stock">
              {product.outOfStock && (
                <KeyboardArrowRightTwoToneIcon
                  sx={{
                    cursor: "pointer",
                    marginLeft: "1vg",
                    stroke: "white",
                    strokeWidth: 0.5,
                    transform: isModalOpen ? "rotate(90deg)" : "rotate(-90deg)",
                    transition: "transform 0.3s ease",
                  }}
                  onClick={toggleModal}
                />
              )}
              {translatedLanguages.inStock}
              {product.outOfStock
                ? translatedLanguages.no
                : translatedLanguages.yes}

              {product.outOfStock && isModalOpen && (
                <div
                  className="modal-content"
                  style={{
                    border: `1px solid ${theme.palette.customOppositeLighter.main}`,
                    top: isSmallScreen ? "38px" : "35px",
                  }}
                >
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <CloseIcon
                      onClick={() => setIsModalOpen(false)}
                      sx={{
                        fontSize: "1.3em",
                        cursor: "pointer",
                        marginLeft: "8px",
                        stroke: "white",
                        strokeWidth: 1,
                      }}
                    />
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: translatedLanguages.outOfStock
                        ?.replace("[OutOfStockTimeFrame]", outOfStockTimeframe)
                        ?.replace("[PhoneNumber]", phone)
                        .replace(/\n/g, "<br />"),
                    }}
                  />
                  <span
                    className="modal-content-p"
                    onClick={() => navigate("/about#not-in-stock")}
                  >
                    About us
                  </span>
                </div>
              )}
            </div>
          </div>
          <Divider
            sx={{
              width: "100%",
              backgroundColor: theme.palette.customOppositeLighter.main,
            }}
            orientation="horizontal"
            variant="left"
            flexItem
          />
          <div className="product-detailed-section">
            <span>{translatedLanguages.colors}</span>
            <div className="product-detailed-section">
              {Array.isArray(product.color) ? (
                product.color.map((color, index) =>
                  colorMap[color.toLowerCase()] ? (
                    <span
                      key={index}
                      className="color-circle"
                      style={{ backgroundColor: colorMap[color.toLowerCase()] }}
                      title={color}
                    ></span>
                  ) : (
                    <span key={index}>{color}</span>
                  )
                )
              ) : colorMap[product.color.toLowerCase()] ? (
                <span
                  className="color-circle"
                  style={{
                    backgroundColor: colorMap[product.color.toLowerCase()],
                  }}
                  title={product.color}
                ></span>
              ) : (
                <span>{product.color}</span>
              )}
            </div>
          </div>
          <Divider
            sx={{
              width: "100%",
              backgroundColor: theme.palette.customOppositeLighter.main,
            }}
            orientation="horizontal"
            variant="left"
            flexItem
          />
          <div className="product-detailed-section">
            <span>{translatedLanguages.filters.heelHeight}:</span>
            <div className="product-detailed-options ">
              <span>{product.heelHeight} cm</span>
            </div>
          </div>
          <Divider
            sx={{
              width: "100%",
              backgroundColor: theme.palette.customOppositeLighter.main,
            }}
            orientation="horizontal"
            variant="left"
            flexItem
          />

          <div
            className="product-detailed-section"
            style={{ margin: "10px 0" }}
          >
            <span style={{ minWidth: isSmallScreen ? "" : "100px" }}>
              {translatedLanguages.sizesChoice}
            </span>
            <div className="product-detailed-options ">
              <>
                {product.size.map((size, index) => (
                  <span
                    key={index}
                    onClick={() => {
                      setSelectedSize(size);
                      setSizeError("");
                    }}
                    className="product-detailed-sizes"
                    style={{
                      backgroundColor:
                        selectedSize === size ? "black" : "white",
                      color: selectedSize === size ? "white" : "black",
                    }}
                  >
                    {size}
                  </span>
                ))}
              </>
              {sizeError && !isSmallScreen && (
                <div style={{ color: "red", marginTop: "10px" }}>
                  {sizeError}
                </div>
              )}
            </div>
          </div>
          <div
            className="product-detailed-add-to-cart"
            onClick={handleAddProductClick}
          >
            <Tooltip title={translatedLanguages.addToCart} placement="bottom">
              <Button
                variant="outlined"
                startIcon={
                  <ShoppingCartIcon
                    sx={{
                      color: "black",
                    }}
                  />
                }
                sx={{
                  border: `1px solid ${theme.palette.customOppositeLighter.main}`,
                  transition: "background-color 0.3s ease, transform 0.3s ease",
                  width: "100%",
                  height: "50px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    border: `1px solid ${theme.palette.customOppositeLighter.main}`,
                  },
                }}
              ></Button>
            </Tooltip>
          </div>
          {isSmallScreen && (
            <div style={{ height: "30px" }}>
              {sizeError && isSmallScreen && (
                <div
                  style={{
                    color: "red",
                    marginTop: "10px",
                    textAlign: isSmallScreen && "center",
                  }}
                >
                  {sizeError}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {similarProducts?.length > 0 && (
        <SectionComponent
          title={translatedLanguages.similarProducts || "Similar Products"}
          filteredProducts={similarProducts}
          isSmallScreen={isSmallScreen}
          isMedium={isMedium}
          navigate={(link) => (window.location.href = link)}
          translatedLanguages={translatedLanguages}
        />
      )}
      {showConfirmationModal && (
        <div className="modal-overlay">
          <div
            className="modal-content-overlay"
            style={{
              border: `1px solid ${theme.palette.customOppositeLighter.main}`,
              width: isSmallScreen ? "80%" : isMedium ? "50%" : "40%",
              fontSize: isSmallScreen ? "0.85em" : "1em",
            }}
          >
            <div style={{ width: "100%", textAlign: "right" }}>
              <CloseIcon
                onClick={() => setShowConfirmationModal(false)}
                sx={{
                  fontSize: "1.3em",
                  cursor: "pointer",
                  marginLeft: "8px",
                }}
              />
            </div>
            <div style={{ width: "85%" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: translatedLanguages.outOfStock
                    ?.replace("[OutOfStockTimeFrame]", outOfStockTimeframe)
                    ?.replace("[PhoneNumber]", phone)
                    .replace(/\n/g, "<br />"),
                }}
              />
              <span
                className="modal-content-p"
                onClick={() => navigate("/about#not-in-stock")}
              >
                About us
              </span>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: translatedLanguages.outOfStockQuestion
                  ?.replace("[OutOfStockTimeFrame]", outOfStockTimeframe)
                  .replace(/\n/g, "<br />"),
              }}
            />
            <CustomButton
              color="black"
              textButton={translatedLanguages.yes}
              onClick={() => {
                dispatch(addItemToCart({ product, size: selectedSize }));
                dispatch(
                  displayNotification({
                    text: `${product.name} ${translatedLanguages.addedToCart}!`,
                    type: NOTIFICATION_TYPES.SUCCESS,
                  })
                );
                setShowConfirmationModal(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductsDetailedPage;
