import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";

const CustomButton = ({
  disabled,
  startIcon,
  endIcon,
  onClick,
  margin,
  width,
  display,
  justifyContent,
  padding,
  height,
  lineHeight,
  fontSize,
  backgroundColor,
  hoverColor,
  hoveBgColor,
  isAdmin,
  deleteButton,
  textButton = "Submit",
  style = {}, // Accept inline styles as a prop
}) => {
  const theme = useTheme();

  const buttonLoginStyles = {
    backgroundColor: backgroundColor || theme.palette.primary.main1,
    color: style.color || (deleteButton ? "red" : "black") || "black",
    border: `1px solid ${theme.palette.customOppositeLighter.main}`,
    transition: "background-color 0.3s ease, transform 0.3s ease",
    textTransform: "uppercase",
    padding: padding || "10px 20px",
    fontSize: fontSize || "0.8em",
    margin: margin,
    width: width,
    height: height || "40px",
    display: display || "flex",
    justifyContent: justifyContent || "center",
    alignItems: "center", // Align content vertically
    fontWeight: isAdmin ? "bold" : "normal",
    lineHeight: lineHeight || "normal", // Set lineHeight here
    "&:hover": {
      backgroundColor: hoveBgColor || hoverColor || theme.palette.secondary.main,
      color: hoverColor || style.color || "black", // Ensure text remains white or respects style
      border: deleteButton
        ? "1px solid red"
        : !isAdmin
        ? `1px solid ${theme.palette.customOpposite.main}`
        : "",
      fontWeight: isAdmin ? "bold" : "normal",
    },
    "&:disabled": {
      backgroundColor: theme.palette.customOppositeLighter.main,
      border: `solid 1px ${theme.palette.primary.opacity80}`,
      color: theme.palette.primary.opacity80,
    },
    ...style, // Merge with inline styles
  };

  return (
    <Button
      type="submit"
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
      sx={buttonLoginStyles}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <span style={{ lineHeight: lineHeight || "1.5" }}>{textButton}</span>
    </Button>
  );
};

export default CustomButton;
