import { createSlice } from "@reduxjs/toolkit";

// Utility function to calculate product-level discount
const calculateDiscountedPrice = (price, discountAmount) => {
  if (typeof price !== "number" || typeof discountAmount !== "number") return price;
  if (discountAmount < 0 || discountAmount > 100) return price;
  return Math.round(price - (price * discountAmount) / 100);
};

// Utility function to calculate global discount
const calculateDiscountedTotal = (total, discountAmount) => {
  if (typeof total !== "number" || typeof discountAmount !== "number") return total;
  if (discountAmount < 0 || discountAmount > 100) return total;
  return Math.max(total - (total * discountAmount) / 100, 0);
};

// Local storage functions
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("cartState");
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (error) {
    console.error("Failed to load cart state:", error);
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("cartState", serializedState);
  } catch (error) {
    console.error("Failed to save cart state:", error);
  }
};

const cartSlice = createSlice({
  name: "cart",
  initialState: loadState() || {
    itemsCount: 0,
    items: [],
    discount: {
      code: null,
      amount: 0, // Global discount percentage
    },
  },
  reducers: {
    incrementItemsCount(state) {
      state.itemsCount += 1;
      saveState(state);
    },
    addItemToCart(state, action) {
      const { product, size } = action.payload;

      const existingItem = state.items.find(
        (item) => item.product._id === product._id && item.size === size
      );

      const productDiscountAmount = product?.discountAmount || 0;
      const discountedPrice = calculateDiscountedPrice(
        product.price,
        productDiscountAmount
      );

      if (existingItem) {
        existingItem.count += 1;
      } else {
        state.items.push({
          count: 1,
          product: { ...product, discountedPrice },
          size,
        });
      }

      state.itemsCount += 1;
      saveState(state);
    },
    decrementItemsCount(state) {
      state.itemsCount -= 1;
      if (state.itemsCount < 0) state.itemsCount = 0;
      saveState(state);
    },
    removeItemsFromCart(state, action) {
      const { id, size } = action.payload;

      const existingItem = state.items.find(
        (item) => item.product._id === id && item.size === size
      );

      if (!existingItem) return;

      existingItem.count -= 1;

      if (existingItem.count === 0) {
        state.items = state.items.filter(
          (item) => !(item.product._id === id && item.size === size)
        );
      }

      state.itemsCount -= 1;
      if (state.itemsCount < 0) state.itemsCount = 0;
      saveState(state);
    },
    clearCart(state) {
      state.items = [];
      state.itemsCount = 0;
      state.discount = { code: null, amount: 0 };
      saveState(state);
    },
    applyDiscountCode(state, action) {
      const { code, amount } = action.payload;

      if (amount >= 0 && amount <= 100) {
        state.discount = { code, amount };
      } else {
        state.discount = { code: null, amount: 0 };
      }
      saveState(state);
    },
  },
});

export const selectTotalCartPrice = (state) => {
  const items = state.cart.items;

  // Check for global discount details
  const discountAmount = state.cart.discount.amount || 0;

  // Calculate total price based on whether a global discount exists
  const total = items.reduce((sum, item) => {
    const price = discountAmount
      ? item.product.price // Use original price if global discount is applied
      : item.product.discountedPrice || item.product.price; // Use discounted price or original price

    return sum + price * item.count;
  }, 0);

  // Apply global discount if present
  const discountedTotal = discountAmount
    ? calculateDiscountedTotal(total, discountAmount)
    : total;

  return Math.ceil(discountedTotal);
};

export const selectDiscountDetails = (state) => ({
  code: state.cart.discount.code,
  amount: state.cart.discount.amount,
});

export const selectItemsCount = (state) =>
  state.cart.items.reduce((count, item) => count + item.count, 0);

export const selectItems = (state) => state.cart.items;

export const {
  incrementItemsCount,
  decrementItemsCount,
  addItemToCart,
  removeItemsFromCart,
  clearCart,
  applyDiscountCode,
} = cartSlice.actions;

export default cartSlice.reducer;
