import { TextField, IconButton, InputAdornment } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PhoneInput from "react-phone-input-2"; // Import PhoneInput
import "react-phone-input-2/lib/style.css"; // Import styles
import { useState } from "react";
import { useTheme } from "@emotion/react";

const CustomField = ({
  label,
  width,
  minWidth,
  size,
  error,
  margin,
  password,
  value,
  borderRadius, 
  fontSize,
  phoneInput,
  onChange,
  regular,
  disabled,
  ...rest
}) => {
  const theme = useTheme();
  const fieldContainer = { margin: margin, width: width };
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={fieldContainer}>
      {phoneInput ? (
        <PhoneInput
          country={"rs"}
          value={value}
          onChange={onChange}
          inputStyle={{
            minWidth: minWidth ? minWidth : "",
            width: "100%",
            height: "40px",
            fontSize: size === "small" ? "0.8em" : "0.9em",
            borderRadius: "20px",
            borderColor: error
              ? "red"
              : theme.palette.customOppositeLighter.main,
          }}
        />
      ) : regular  ? (
        <TextField
          placeholder={label}
          variant="outlined"
          size={size ? size : "small"}
          fullWidth
          type={password ? (showPassword ? "text" : "password") : "text"}
          value={value}
          onChange={onChange}
          error={!!error}
          disabled={disabled ? disabled : false}
          InputProps={{
            style: {
              borderRadius: borderRadius || "8px", 
              fontSize: fontSize || "1em", 
            },
            endAdornment: password && (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.palette.customOppositeLighter.main,
              },
              "&:hover fieldset": {
                borderColor: theme.palette.customOpposite.main,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.customOpposite.main,
                borderWidth: "2px",
              },
            },
          }}
        />
      ) : (
        <Field
          as={TextField}
          {...rest}
          placeholder={label}
          variant="outlined"
          size={size ? size : "small"}
          fullWidth
          type={password ? (showPassword ? "text" : "password") : "text"}
          value={value}
          error={!!error}
          InputProps={{
            style: {
              borderRadius: borderRadius || "8px", 
              fontSize: fontSize || "1em", 
            },
            endAdornment: password && (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.palette.customOppositeLighter.main,
              },
              "&:hover fieldset": {
                borderColor: theme.palette.customOpposite.main,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.customOpposite.main,
                borderWidth: "2px",
              },
            },
          }}
        />
      )}
      {!error ? null : (
        <div className="checkout-step-error">
          <ErrorMessage name={rest.name} component="div" className="error" />
        </div>
      )}
    </div>
  );
};

export default CustomField;
