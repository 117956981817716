export const translations = {
  en: {
    english: "English",
    serbian: "Serbian",
    home: "Home",
    search: "Search...",
    links: "Links",
    aboutUs: "About us",
    contact: "Contact",
    services: "Services",
    contactUs: "Contact Us",
    shop: "Shop",
    followUs: "Follow Us",
    salesInfo: "Sales info",
    privacyPolicy: "Privacy policy",
    terms: "Terms and conditions ",
    paymentMethods: "Payment Methods",
    resetFilters: "Reset",
    applyFilters: "Apply",
    timeoutMessage: "Session Expired",
    sessionTimeoutExplanation:
      "Your session has expired. Please refresh the page or continue shopping.",
    cart: "Cart",
    discountCode: "You have a discount code?",
    discountCodeError: "Enter the code",
    cartReview: "Review cart",
    checkout: "Checkout",
    checkoutComplete: "Confirm Order",
    orderSent: "Your order has been successfully sent!",
    thankYou: "Thank you for choosing to shop with us.",
    confirmation: "Confirmation",
    cartEmpty: "Cart is empty",
    continueShopping: "Continue shopping",
    addToCart: "Add to cart",
    errorLoadingProduct: "Error loading product.",
    loadingProduct: "Loading product details...",
    noProducts: "No products available.",
    viewAllProducts: "View all products ",
    add: "Add",
    remove: "Remove",
    addedToCart: "added to cart",
    removedFromCart: "removed from cart",
    total: ":Total",
    totalLeft: "Total:",
    reviewCart: "< Review cart",
    reviewCartLink: "Review cart",
    noProductsFiltered: "No product matches the filters.",
    filteringSuccess: "Filtering complete!",
    filteringInProggress: "Filtering products...",
    colors: "Colors:",
    sizesChoice: "Chose a size:",
    selectSizeError: "Select a size!",
    similarProducts: "Similar products",
    inStock: "In stock: ",
    outOfStock:
      "This product is currently out of stock. You can still place an order, and it will be delivered within [OutOfStockTimeFrame] bussines days. Alternatively, you can order via WhatsApp([PhoneNumber]). For more information, please visit the following page:",
    outOfStockQuestion:
      "Would you like to place an order? It will be delivered within [OutOfStockTimeFrame] bussines days.",
    outOfStockQuestionAbout: "Product you wish to order is not in stock",
    delivery: "Delivery ",
    yes: "Yes",
    no: "No",
    from: "of",
    number: "num.",
    image: "Image",
    name: "Name",
    size: "Size",
    price: "Price",
    quantity: "Qua.",
    actions: "ac.",
    filter: "Filters: ",
    filters: {
      tags: "Tags",
      price: "Price",
      color: "Color",
      heelHeight: "Heel Height",
      size: "Size",
      new: "New",
      sale: "Sale",
      women: "Women",
      man: "Men",
      kids: "Kids",
      boots: "Boots",
      bestseller: "Bestseller",
      colors: {
        red: "Red",
        blue: "Blue",
        green: "Green",
        black: "Black",
        white: "White",
        yellow: "Yellow",
        purple: "Purple",
        orange: "Orange",
        brown: "Brown",
        gray: "Gray",
        gold: "Gold",
        silver: "Silver",
        bronze: "Bronze",
        pink: "Pink",
        beige: "Beige",
        jeans: "Jeans",
      },
    },
    ordersForm: {
      email: "Email",
      name: "Full Name",
      address: "Address",
      city: "City",
      zipCode: "Postal Code",
      phoneNumber: "Phone Number",
      paymentMethod: "Payment method",
      creditCard: "Credit Card",
      bankAccount: "Pay to bank account",
      cashOnDelivery: "Cash on Delivery",
      acceptTerms: "I agree to the terms and conditions",
      invoiceForPayment: "Invoice for payment",
      bankAccountOption: "Account number:",
       bankAccountNotification: "The order will be shipped after payment to the account!",
       scanQrToPay:"Scan this QR code to pay via your banking app!",
       orderID: "Order ID",
       fillRequiredFields:"Please fill out all the fields to generate the QR code.",
    },
    heading: "The Passion Behind the Steps",
    content: `
		At Soul and Sole, we believe that every dancer deserves shoes as graceful as their movements. Our collection is thoughtfully designed to blend elegance, comfort, and precision, allowing you to dance with confidence. Whether you're performing on stage or refining your technique in the studio, our shoes are crafted to support your passion with every step.

		Each pair of our dance shoes is made from premium materials, providing durability without compromising style. We understand that a dancer's shoes are an extension of their body, which is why we prioritize flexibility, breathability, and a perfect fit in every design. From Latin ballroom to contemporary styles, our shoes are tailored to meet the unique needs of dancers across various genres.

		We also recognize that every dancer has their own distinctive style. That’s why we offer a wide range of customizable options, from heel height to color. We want you to feel confident not only in your movements but in the way you express your personal style. Create a look that’s truly yours and let your personality shine through every step.

		Our commitment to excellence goes beyond aesthetics. We work closely with professional dancers to refine every product, ensuring that our shoes meet the highest standards of quality and performance. With Soul and Sole, you're not just buying shoes—you're investing in craftsmanship that enriches your dance experience.
		`,

    outOfStockQA: `
    Out of stock information:\n\n
    - If a product is currently out of stock, you can still place an order, and the product will be delivered within [OutOfStockTimeFrame] bussines days.\n
    - Alternatively, you can order via WhatsApp([PhoneNumber]) for quicker assistance.\n
    - For more information or assistance, please contact us at [Email].
`,

    deliveryMethodQA: `
		Delivery:\n\n
		- We offer delivery within Serbia and internationally. \n
		- Delivery times range from [GeneralTimeframe] business days, depending on the location of the customer. With exception for territory of Belgrade, in this case the order can be delivered on the same day.\n
		- Deliveries within Serbia typically take [GeneralTimeframe] business days, while international delivery may take [InternationalTimeframe] business days or more, depending on the destination.\n
		- Delivery is provided by our partner courier services, and tracking information will be provided via email once the order is shipped.\n
		- Shipping fees may apply for certain orders, depending on the order total and destination.\n
		- Customers are responsible for ensuring that the delivery address provided is correct. If a delivery is returned due to an incorrect address, the customer may be responsible for additional shipping fees.\n
		- For any issues or questions regarding shipping, please contact us at [Email].
	`,
    paymentMethodQA: `
    Payment Methods:\n\n
    Currently, we offer the following payment options for orders:\n
    - Cash on Delivery: Customers can pay in cash when the products are delivered to their address.\n
    - Bank Transfer: Customers can make payments directly to our bank account. Once the payment is confirmed, the order will be processed and shipped.\n
    Please note, we do not currently accept credit card payments. Other payment options may be introduced in the future, and updates will be made available on our website.\n
    For any issues or questions regarding payment, please contact us at [Email].
`,

    privacyPolicyQA: `
    Privacy Policy:\n\n
    - We collect and store customer data in compliance with applicable laws, including the Serbian Law on Personal Data Protection and the General Data Protection Regulation (GDPR) for international customers.\n
    - The data we collect includes, but is not limited to, customer names, email addresses, phone numbers, and shipping addresses, which are necessary to process orders and provide customer support.\n
    - We use customer information solely for the purpose of fulfilling orders, improving customer experience, and communication related to order status, marketing updates (with consent), and promotions.\n
    - Your information will not be shared with third parties without your explicit consent, except when necessary for processing orders (e.g., sharing delivery details with courier services).\n
    - Customers have the right to request access to their data, correction of inaccuracies, or complete deletion of their personal data by contacting us at [Email].\n
    - We implement appropriate technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction.\n
    - If a data breach occurs that may compromise your personal information, we will notify you and the relevant authorities in accordance with applicable laws.\n
    - Our website may use cookies and similar technologies to enhance user experience, track orders, and personalize content. By using our website, you consent to the use of cookies.\n
`,
    termsAndConditions: `
		1. Introduction:\n\nSoul and Sole, a company registered under the name [CompanyLegalName], with registration number [RegistrationNumber] and PIB [PIB], operates the website www.soulandsoledanceshoes.com and other associated e-commerce platforms.\n This document defines the terms under which we operate. These terms may be updated, and the most current version will be available on our website.\n\n By accessing our website, you agree to the following conditions.\n\n
		2. Definitions:\n\n - “Soul and Sole” refers to our company, [CompanyLegalName].\n - “Website” refers to www.soulandsoledanceshoes.com and any related e-commerce platforms.\n - “Customer” refers to anyone who uses the website or purchases goods from Soul and Sole.\n - “Products” refers to all items available for sale on the website, including professional dance shoes and related accessories.\n\n
		3. Product Information:\n\n - All products listed on our website are described accurately, with details about size, color, material, and purpose. \n - Images are for illustrative purposes, and there may be slight variations due to display settings.\n\n
		4. Pricing and Payments:\n\n - Prices are displayed in RSD, including applicable taxes.\n - Payments can be made via cash on delivery, paying directly to bank account, or other payment methods listed on the website.\n\n
		5. Shipping and Delivery:\n\n - We offer delivery within Serbia and internationally. Delivery times range from [GeneralTimeframe] bussines days depending on location.\n - Delivery is provided by partner courier services, and tracking information will be provided via email.\n\n
		6. Return and Exchange Policy:\n\n  - You have the right to return products within 14 days of delivery if they are unused and in original condition.\n\n
		7. Customer Rights:\n\n - Customers can contact us at [Email] for complaints or queries.\n - If a dispute arises, customers have the right to pursue out-of-court dispute resolution procedures.\n\n
		8. Privacy Policy:\n\n - We collect and store customer data in compliance with Data Protection Laws. \n - Your information will not be shared with third parties without consent, except for the purpose of fulfilling orders.\n\n
		9. Intellectual Property:\n\n All content on our website, including images, logos, and text, is the intellectual property of Soul and Sole. Unauthorized use or reproduction is prohibited.\n\n
		10. Final Provisions:\n\n These terms are subject to change. Any updates will be posted on our website. \n\n By continuing to use our services, you agree to these terms.
	`,
  },
  sr: {
    english: "Engleski",
    serbian: "Srpski",
    home: "Početna",
    search: "Pretraga...",
    links: "Linkovi",
    aboutUs: "O nama",
    contact: "Kontakt",
    services: "Usluge",
    contactUs: "Kontaktirajte Nas",
    shop: "Prodavnica",
    followUs: "Pratite Nas",
    salesInfo: "Informacije o prodaji",
    privacyPolicy: "Politika privatnosti",
    terms: "Opšti uslovi kupovine ",
    paymentMethods: "Metode plaćanja",
    resetFilters: "Resetuj",
    applyFilters: "Primeni",
    timeoutMessage: "Sesija istekla",
    sessionTimeoutExplanation:
      "Vaša sesija je istekla. Molimo osvežite stranicu ili nastavite sa kupovinom.",
    cart: "Korpa",
    discountCode: "Imate popust kod?",
    discountCodeError: "Unesite kod",
    cartReview: "Pregled korpe",
    checkout: "Završetak kupovine",
    checkoutComplete: "Potvrda porudžbine",
    orderSent: "Vaša porudžbina je uspešno poslata!",
    thankYou: "Hvala Vam što ste izabrali da kupujete kod nas.",
    confirmation: "Potvrda",
    cartEmpty: "Korpa je prazna",
    continueShopping: "Nastavi sa kupovinom",
    addToCart: "Dodaj u korpu",
    errorLoadingProduct: "Greška pri učitavanju proizvoda.",
    loadingProduct: "Učitavanje proizvoda...",
    noProducts: "Nema dostupnih proizvoda.",
    viewAllProducts: "Prikazi sve proizvode ",
    add: "Dodaj",
    remove: "Ukloni",
    addedToCart: "dodat u korpu",
    removedFromCart: "uklonjen iz korpe",
    total: ":Ukupno",
    totalLeft: "Ukupno:",
    reviewCart: "< Pregled korpe",
    reviewCartLink: "Pregled korpe",
    noProductsFiltered: "Nijedan proizvod ne odgovara filterima.",
    filteringSuccess: " Filtriranje završeno!",
    filteringInProggress: "Filtriranje u toku......",
    colors: "Boje:",
    sizesChoice: "Izaberi veličinu:",
    selectSizeError: "Izaberite veličinu!",
    similarProducts: "Slični proizvodi",
    inStock: "Na stanju: ",
    outOfStock:
      "Proizvod trenutno nije na zalihama. Možete ga naručiti, a isporuka će biti izvršena u roku od [OutOfStockTimeFrame] radnih dana. Takođe, narudžbinu možete izvršiti putem WhatsApp-a([PhoneNumber]). Za više informacija, posetite sledeću stranicu:",
    outOfStockQuestion:
      "Da li želite da naručite proizvod? Biće isporučen u roku od [OutOfStockTimeFrame] radnih dana.",
    outOfStockQuestionAbout: "Proizvod koji želite da naručite nije na stanju ",
    delivery: "Isporuka ",
    yes: "Da",
    no: "Ne",
    from: "od",
    number: "br.",
    image: "Slika",
    name: "Naziv",
    size: "Veličina",
    price: "Cena",
    quantity: "Kol.",
    actions: "ak.",
    filter: "Filteri: ",
    filters: {
      tags: "Tagovi",
      price: "Cena",
      color: "Boja",
      heelHeight: "Visina Štikle",
      size: "Veličina",
      new: "Novo",
      sale: "Rasprodaja",
      women: "Ženske",
      man: "Muške",
      kids: "Dečije",
      boots: "Čizme",
      bestseller: "Najprodavanije",
      colors: {
        red: "Crvena",
        blue: "Plava",
        green: "Zelena",
        black: "Crna",
        white: "Bela",
        yellow: "Žuta",
        purple: "Ljubičasta",
        orange: "Narandžasta",
        brown: "Braon",
        gray: "Siva",
        gold: "Zlatna",
        silver: "Srebrna",
        bronze: "Bronzana",
        pink: "Roze",
        beige: "Bež",
        jeans: "Džins",
      },
    },
    ordersForm: {
      email: "Email",
      name: "Ime i Prezime",
      address: "Adresa",
      city: "Grad",
      zipCode: "Poštanski broj",
      phoneNumber: "Broj telefona",
      paymentMethod: "Način plaćanja",
      creditCard: "Kreditna Kartica",
      bankAccount: "Uplata na račun",
      cashOnDelivery: "Plaćanje pouzećem",
      acceptTerms: "Prihvatam uslove korišćenja",
      invoiceForPayment: "Faktura za uplatu",
      bankAccountOption: "Broj računa:",
      bankAccountNotification: "Narudžbina će biti poslata nakon uplate na račun!",
      scanQrToPay:"Skenirajte ovaj QR kod da platite putem vaše bankarske aplikacije!",
      orderID: "ID Narudžbine",
      fillRequiredFields:"Molimo popunite sva polja kako biste generisali QR kod.",
    },
    heading: "Strast Iza Koraka",
    content: `
		U Soul and Sole-u verujemo da svaki plesač zaslužuje cipele koje su graciozne poput njihovih pokreta. Naša kolekcija je pažljivo osmišljena da spoji eleganciju, udobnost i preciznost, omogućavajući vam da plešete sa samopouzdanjem. Bilo da nastupate na sceni ili usavršavate tehniku u studiju, naše cipele su napravljene da podrže vašu strast na svakom koraku.

		Svaki par naših plesnih cipela izrađen je od vrhunskih materijala, koji pružaju izdržljivost bez kompromisa po pitanju stila. Razumemo da su plesne cipele produžetak tela, zbog čega dajemo prioritet fleksibilnosti, prozračnosti i savršenom pristajanju u svakom dizajnu. Od latino plesova do savremenih stilova, naše cipele su prilagođene specifičnim potrebama plesača različitih žanrova.

		Takođe, razumemo da svaki plesač ima svoj jedinstveni stil. Zato nudimo širok spektar mogućnosti za prilagođavanje, od visine štikle do boje. Želimo da se osećate sigurno, ne samo u svojim pokretima već i u načinu na koji izražavate svoj lični stil. Kreirajte izgled koji je zaista vaš i neka vaša ličnost zablista u svakom koraku.

		Naša posvećenost izvrsnosti prevazilazi estetiku. Usko sarađujemo sa profesionalnim plesačima kako bismo usavršili svaki proizvod i osigurali da naše cipele ispunjavaju vaša očekivanja. Sa Soul and Sole-om, ne kupujete samo cipele—ulažete u kvalitet koji obogaćuje vaše plesno iskustvo.
		`,
    outOfStockQA: `
    Informacije o nedostupnim proizvodima: \n\n
    - Ukoliko proizvod trenutno nije na stanju, i dalje možete napraviti porudžbinu, a proizvod će biti isporučen u roku od [OutOfStockTimeFrame] radnih dana.\n
    - Alternativno, možete naručiti putem WhatsApp-a([PhoneNumber]) za bržu pomoć.\n
    - Za više informacija ili pomoć, kontaktirajte nas putem e-maila na [Email].
`,

    deliveryMethodQA: `
		Dostava:\n\n
		- Nudimo dostavu unutar Srbije i na međunarodnom nivou. \n
		- Vreme isporuke varira od [GeneralTimeframe] radna dana, u zavisnosti od lokacije kupca. Sa izuzetkom na teritoriji Beograda, gde porudžbina može biti dostavljena istog dana.\n
		- Isporuke unutar Srbije obično traju [GeneralTimeframe] radnih dana, dok međunarodna isporuka može trajati [InternationalTimeframe] radnih dana ili više, u zavisnosti od odredišta.\n
		- Dostavu obavljaju naši partnerski kurirski servisi, a informacije o praćenju pošiljke biće poslate putem e-maila čim porudžbina bude poslata.\n
		- Troškovi dostave mogu se primeniti za određene porudžbine, u zavisnosti od ukupne vrednosti porudžbine i destinacije.\n
		- Kupci su odgovorni za tačnost dostavljene adrese. Ukoliko se pošiljka vrati zbog netačne adrese, kupac može biti odgovoran za dodatne troškove dostave.\n
		- Za bilo kakve probleme ili pitanja u vezi sa dostavom, molimo vas da nas kontaktirate na [Email].
	`,

    paymentMethodQA: `
		Načini Plaćanja:\n\n
		Trenutno nudimo sledeće opcije plaćanja za porudžbine:\n
		- Plaćanje po dostavi: Kupci mogu platiti gotovinom kada proizvodi budu isporučeni na njihovu adresu.\n
		- Bankovni transfer: Kupci mogu izvršiti uplatu direktno na naš bankovni račun. Kada uplata bude potvrđena, porudžbina će biti obrađena i isporučena.\n
		Napominjemo da trenutno ne prihvatamo plaćanje kreditnim karticama. Druge opcije plaćanja mogu biti uvedene u budućnosti, a ažuriranja će biti dostupna na našem sajtu.\n
		Za sva pitanja ili probleme u vezi sa plaćanjem, molimo vas da nas kontaktirate na [Email].
	`,
    privacyPolicyQA: `
    Politika Privatnosti:\n\n
    - Prikupljamo i čuvamo podatke o korisnicima u skladu sa važećim zakonima, uključujući Zakon o zaštiti podataka o ličnosti Republike Srbije i Opštu uredbu o zaštiti podataka (GDPR) za međunarodne korisnike.\n
    - Podaci koje prikupljamo uključuju, ali nisu ograničeni na, imena korisnika, adrese e-pošte, brojeve telefona i adrese za dostavu, koji su neophodni za obradu porudžbina i pružanje korisničke podrške.\n
    - Korisničke informacije koristimo isključivo u svrhu ispunjavanja porudžbina, poboljšanja korisničkog iskustva, i komunikacije u vezi sa statusom porudžbina, marketinškim obaveštenjima (uz saglasnost) i promocijama.\n
    - Vaši podaci neće biti deljeni sa trećim stranama bez vaše izričite saglasnosti, osim kada je to neophodno za obradu porudžbina (npr. deljenje podataka o dostavi sa kurirskim službama).\n
    - Korisnici imaju pravo da zatraže pristup svojim podacima, ispravljanje netačnih podataka ili potpuno brisanje ličnih podataka tako što će nas kontaktirati na [Email].\n
    - Primenjujemo odgovarajuće tehničke i organizacione mere kako bismo zaštitili vaše podatke od neovlašćenog pristupa, izmene, otkrivanja ili uništenja.\n
    - U slučaju da dođe do povrede podataka koja bi mogla ugroziti vaše lične informacije, obavestićemo vas i nadležne organe u skladu sa važećim zakonima.\n
    - Naš veb-sajt može koristiti kolačiće i slične tehnologije kako bi unapredio korisničko iskustvo, pratio porudžbine i personalizovao sadržaj. Korišćenjem našeg veb-sajta, pristajete na upotrebu kolačića.\n
`,

    termsAndConditions: `
		1. Uvodne Odredbe:\n\nSoul and Sole, kompanija registrovana pod nazivom [CompanyLegalName], sa matičnim brojem [RegistrationNumber] i PIB-om [PIB], upravlja veb-sajtom www.soulandsoledanceshoes.com i povezanim e-commerce platformama. Ovi uslovi se mogu ažurirati, a najnovija verzija će biti dostupna na našem sajtu.\n\nPristupom našem sajtu, slažete se sa sledećim uslovima.\n\n
		2. Definicije:\n\n - “Soul and Sole” označava našu kompaniju [CompanyLegalName].\n - “Veb-sajt” odnosi se na www.soulandsoledanceshoes.com i povezane e-commerce platforme.\n - “Kupac” označava svakog korisnika koji koristi veb-sajt ili kupuje robu od Soul and Sole-a.\n - “Proizvodi” odnose se na sve artikle dostupne za prodaju na sajtu, uključujući profesionalne plesne cipele i prateće aksesoare.\n\n
		3. Informacije o Proizvodima:\n\n - Svi proizvodi na našem sajtu su tačno opisani sa detaljima o veličini, boji, materijalu i nameni. \n - Slike su ilustrativne prirode i može doći do manjih odstupanja zbog postavki prikaza.\n\n
		4. Cene i Načini Plaćanja:\n\n - Cene su izražene u RSD, uključujući poreze.\n - Plaćanje se može izvršiti putem kešom prilikom dostave,cuplatom direktno na račun ili drugim dostupnih metoda navedenih na sajtu.\n\n
		5. Dostava i Isporuka:\n\n  - Dostava je moguća unutar Srbije i na međunarodnom nivou. Vreme isporuke zavisi od lokacije i kreće se od [GeneralTimeframe] radna dana.\n - Dostavu vrše naši partnerski kurirski servisi, a informacije o praćenju pošiljke biće poslati putem e-maila.\n\n
		6. Politika Povraćaja i Zamene:\n\n - Imate pravo da vratite proizvode u roku od 14 dana od isporuke, pod uslovom da su nekorišćeni i u originalnom stanju.\n\n
		7. Prava Kupaca:\n\n - Kupci nas mogu kontaktirati putem [Email] za žalbe ili upite.\n - U slučaju spora, kupci imaju pravo na vansudsko rešavanje potrošačkih sporova.\n\n
		8. Politika Privatnosti:\n\n - Prikupljamo i čuvamo podatke o kupcima u skladu sa važećim zakonima o zaštiti podataka. \n - Vaši podaci neće biti deljeni sa trećim licima bez Vaše saglasnosti, osim u svrhu realizacije porudžbina.\n\n
		9. Intelektualna Svojina:\n\n - Sav sadržaj na našem sajtu, uključujući slike, logotipe i tekstove, je intelektualna svojina Soul and Sole-a. \n - Neovlašćeno korišćenje, reprodukcija ili distribucija ovog sadržaja je zabranjena.\n\n
		10. Završne Odredbe:\n\n Ovi uslovi su podložni promenama. Sve izmene biće objavljene na našem sajtu. \n\n Korišćenjem naših usluga, slažete se sa ovim uslovima.
	`,
  },
};
